*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.videoTag{
position: fixed;
right: 0;
bottom: 0;
width: auto;
min-width: 100%;
height: auto;
min-height: 100%;
z-index: -100;
}

.weather-box{
 
  
  width: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 8px 20px 20px rgb(0, 0,0,0.5);
  text-align: center;
}

/* .weather-box button{
  background-color: red;
  position: relative;
  font-size: 1rem;
  padding: 0.4em 0.5em;
  background-color: #bf0426;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 0.5em;
  color: #dedede;
  box-sizing: 0.5em 0.5em 0.5em #0000004d;
}
.weather-box button::before{
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  background: linear-gradient(135deg,#212121 0%, #212121 50%, #96041f 50%,#bf0426 60%);
  border-radius: 0 0 0.5 0;
  box-shadow: 0.2em 0.2em 0.2em #0000004d;
  transition: 0.3s;
} */

/* 
.content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}
.content > i{
  font-size: 44px;
  transition: all .2s ease-in;
} */

/* 
.weather-box button:hover::before{
  width: 1.6em;
  height: 1.6em;
}
.weather-box button:active{
  box-shadow: 0.2em 0.2em 0.3em #0000004d;
  transform: translate(0.1em,0.1em);
} */


/* custom checkbox*/
.switch {
    position: relative;
    display: block;
    height: 15px;
    width: 40px;
    background: #303030;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .switch:after {
    position: absolute;
    left: 1px;
    top: -3px;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: black;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
    content: '';
    transition: all 0.3s ease;
  }
  .switch:active:after { transform: scale(1.15, 0.85); }
  .cbx:checked ~ label { background: #EBEBEB; }
  .cbx:checked ~ label:after {
    left: 20px;
    background: #FFFFFF;
  }
  .cbx:disabled ~ label {
    background: #d5d5d5;
    pointer-events: none;
  }
  .cbx:disabled ~ label:after { background: #bcbdbc; }
  .hidden { display: none; }


.weather-header{
  color: white;
  margin: 15px;
}

.weather-input{
  border: none;
  outline: none;
  
  margin: 10px;
}
.weather-row{
  display: flex;
  flex-direction: row;
  color: white;
  margin: 20px;
  padding: auto;
}

.weather-column{
  flex-basis: 50%;
}

.weather-city{
  color: white;
  margin: 20px;
}

@media screen and (max-width: 480px) {
  .weather-box{
  width: 300px;
  }
  h6#lasth{
    color: red;
  }

  
.weather-box button::before{
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  /* background: linear-gradient(135deg,#212121 0%, #212121 50%, #96041f 50%,#bf0426 60%);
  border-radius: 0 0 0.5 0;
  box-shadow: 0.2em 0.2em 0.2em #0000004d;
  transition: 0.3s; */
}
}
